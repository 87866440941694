import React from 'react'
import '../styles/SocialIcons.css'
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

function SocialIcons() {
  return (
    <div className="social">
      <a href="https://www.linkedin.com/in/cferrisroig/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
      <a href="https://github.com/cferrisroig" target="_blank" rel="noreferrer"><GitHubIcon/></a>
      {/* <a href="./" target="_blank" rel="noreferrer"><EmailIcon/></a> */}
    </div>
  )
}

export default SocialIcons