export const SkillsCoding = [
  {
    name: "HTML5",
    level: 90
  },
  {
    name: "PYTHON",
    level: 50
  },
  {
    name: "CSS3",
    level: 75
  },
  {
    name: "JavaScript",
    level: 65
  },
  {
    name: "NPM",
    level: 20
  },
  {
    name: "ReactJS",
    level: 20
  },
  {
    name: "PHP",
    level: 15
  }
]