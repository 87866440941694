export const SkillsLanguages = [
  {
    name: "Spanish",
    level: 100
  },
  {
    name: "English",
    level: 85
  },
  {
    name: "Catalan",
    level: 100
  }
]