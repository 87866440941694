import { useEffect } from 'react'
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';

function Experience() {
  useEffect(() => { window.scrollTo(0, 0) }, []) // To move scroll ot the top after every render
  const styles = {
    location: {
      color: "var(--light-col)", 
      marginTop: "0.5rem"
    },
    university: {
      color: "var(--light-col)", 
      marginTop: "0.5rem", 
      fontWeight: "bold"
    }
  };
  const iconStyle = {
    work: {
      background: "var(--cont-col)", color:"#fff"
    },
    studies: {
      background: "var(--light-col)", color:"#fff"
    }
  }

  return (
    <>
      <VerticalTimeline lineColor="var(--main-col)">
        <VerticalTimelineElement 
          className="vertical-timeline-element--work"
          date="2014/05 - 2022/11"
          iconStyle={iconStyle.work}
          icon={<WorkIcon/>}>
            <h4 className="vertical-timeline-element-title">R&D Mechanical Engineer - HP Inc</h4>
            <h5 style={styles.location}>Barcelona, Spain</h5>
            <p>In charge of the Research and Development of new mechanical, pneumatic and thermal systems, and their implementation in the plastics and metals 3D printers. From concept to production. </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement 
          className="vertical-timeline-element--education"
          date="2014 - 2016"
          iconStyle={iconStyle.studies}
          icon={<SchoolIcon/>}>
            <h4 className="vertical-timeline-element-title">M.S. Project Management </h4>
            <p style={styles.university}>laSalle, Universitat Ramón Llull (URL)</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement 
          className="vertical-timeline-element--work"
          date="2013/09 - 2013/12"
          iconStyle={iconStyle.work}
          icon={<WorkIcon/>}>
            <h4 className="vertical-timeline-element-title">ME Design Engineer - Alois Pöttinger Maschinenfabrik</h4>
            <h5 style={styles.location}>Grieskirchen, Austria</h5>
            <p>Responsible for redesigning the loading wagon’s cutting unit in order to improve the power consumption and optimise the cutting process. </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement 
          className="vertical-timeline-element--education"
          date="2010 - 2013"
          iconStyle={iconStyle.studies}
          icon={<SchoolIcon/>}>
            <h4 className="vertical-timeline-element-title">M.S. Mechanical Engineer</h4>
            <p style={styles.university}>ETSII, Universitat Politècnica de Valencia (UPV)</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement 
          className="vertical-timeline-element--work"
          date="2010/03 - 2010/08"
          iconStyle={iconStyle.work}
          icon={<WorkIcon/>}>
            <h4 className="vertical-timeline-element-title">Sales Engineer - Grupotec Tecnología Solar</h4>
            <h5 style={styles.location}>Valencia, Spain</h5>
            <p>Support to the sales department by appraising the optimum number and orientation of the solar panels on industrial roofs. </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement 
          className="vertical-timeline-element--education"
          date="2006 - 2010"
          iconStyle={iconStyle.studies}
          icon={<SchoolIcon/>}>
            <h4 className="vertical-timeline-element-title">B.S.E. Mechanical Engineer</h4>
            <p style={styles.university}>ETSID, Universitat Politècnica de Valencia (UPV)</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </>
  )
}

export default Experience