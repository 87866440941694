import hp4200 from '../assets/hp-mjf-4200.jpg'
import hp5200 from '../assets/hp-mjf-5200.jpg'
import hps100 from '../assets/hp-mjf-S100.jpg'
import sincarta from '../assets/sin-carta.jpg'
import stacking from '../assets/stacking-tolerances.jpg'
import brokers from '../assets/compara-brokers.jpg'
import portfolio from '../assets/portfolio.jpg'

export const ProjectList = {
  "professional": [
    {
      name: "HP MJF S100",
      image: hps100,
      skills: "Vacuum engineering, Sensor and actuators selection, GD&T, Design for Manufacturing (DFM), Design for Assembly (DFA), DFMEA, Rapid Prototyping, Python (data analysis), Project Management",
      description: "In charge of the research and development of a new system to extract and condensate unwanted ink's components from metals 3D printers. Taking the design from prototype to production, including testing, data analysis and documentation.",
      achievements: ["Reduce cost of maintenance by 50% compared to first prototypes", "Improved process control and diagnosticability by the introduction of new sensors and algorithms", "Manage the work of two engineers (direct reports) and integration of the whole system within other disciplines (EE, FW, Customer Assurance)"],
      link: "https://www.hp.com/us-en/printers/3d-printers/products/metal-jet.html"
    },
    {
      name: "HP MJF 5200",
      image: hp5200,
      skills: "Thermal engineering, Flexible heater dimensioning and design, Thermal testing (IR cameras, Thermocouples and RTC), Thermal simulations, Design for Manufacturing (DFM), Design for Assembly (DFA), Python, TCL", 
      description: "In charge of the development of an improved heating system and temperature control for the powder in the plastics 3D printer. ",
      achievements: ["Cost reduction of 25% for the overall subsystem compared to previous design", "Increased working range of the subsystem by 20% compared to previous design"],
      link: "https://www.hp.com/us-en/printers/3d-printers/products/multi-jet-fusion-5200.html"
    },
    {
      name: "HP MJF 4200",
      image: hp4200,
      skills: "Thermal engineering, Flexible heater dimensioning and design, Thermal testing (IR cameras, Thermocouples and RTC), Data acquisition, Microsoft Excel, DFMEA, Design for Manufacturing (DFM), Design for Assembly (DFA), TCL", 
      description: "Design (from first prototypes to production) of a new thermal system to heat-up and control powder temperature before and after printing, in a plastics 3D printer. ",
      achievements: [],
      link: "https://www.hp.com/us-en/printers/3d-printers/products/multi-jet-fusion-4200.html"
    }
  ],
  "personal": [
    {
      name: "Personal Portfolio",
      image: portfolio,
      skills: "JavaScript (React), CSS3, HTML5, npm",
      description: "Online portfolio to show my experience and skills as a Mechanical Engineer and as a Web developer. ",
      achievements: ["First web application using npm and React", "Use of own and third party components"],
      link: "https://cferrisroig.com/"
    },
    {
      name: "Stacking Tolerances",
      image: stacking,
      skills: "JavaScript, CSS3 & PicoCSS, HTML5, CodeIgniter 4",
      description: "Online app to do tolerance stack-up analysis for mechanical engineers with a clean and appealing UX. ",
      achievements: ["Monte Carlo simulation run on the browser, removing the need of use of complicated Excel sheets", "(Coming) User management to allow saving and sharing results "],
      link: "https://stackingtolerances.com/"
    },
    {
      name: "Compara Brokers",
      image: brokers,
      skills: "HTML5, CSS3 & SkeletonCSS, JavaScript, CodeIgniter 4, SEO",
      description: "Reviews and comparisons for different spanish brokers, and other investing related information. ",
      achievements: ["Regular visits to certain posts", "Online calculator for compound interest"],
      link: "https://comparabrokers.es/"
    },
    {
      name: "Sin-Carta",
      image: sincarta,
      skills: "CSS3, HTML5, JavaScript vanilla, CodeIgniter 4",
      description: "Project to help restaurants have an online menu (developed during COVID lockdown). ",
      achievements: ["Responsive design with easy to read lists of dishes and ingredients", "Possibility to apply allergens filters to improve customer experience"],
      link: "https://sin-carta.es/"
    }
  ]
}