import React, {useState, useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import '../styles/Navbar.css'
import ReorderIcon from '@mui/icons-material/Reorder';
import CloseIcon from '@mui/icons-material/Close';

function Navbar() {
  const [expandNavbar, setExpandNavbar] = useState(false);
  const location = useLocation();
  useEffect(() => { setExpandNavbar(false); }, [location]);

  return (
	<nav className={expandNavbar? "open" : "close"}>
    <button id="toggleButton" onClick={() => {setExpandNavbar((prev) => !prev)}}>
      <ReorderIcon id="openMenu"/>
      <CloseIcon id="closeMenu"/>
    </button>
    <div className="links">
      <Link to="/"> Home </Link>
      <Link to="/projects"> Projects </Link>
      <Link to="/experience"> Experience </Link>
    </div>
	</nav>
  )
}

export default Navbar