import '../styles/SkillBarList.css'

function SkillBarList({skills}) {
  return (
    <div class="skill-list">
      { skills.map( (s) => <SkillBar skill={s.name} progress={s.level}/>) }
    </div>
  )
}

function SkillBar({skill, progress}) {
  const bar_width = progress + "%"

  return (
    <>
    <div class="skill">
      <p class="skill-name">{skill} <span style={{color: "grey", fontSize: "0.8rem", verticalAlign: "middle"}}>({progress}%)</span></p>
      <div class="skill-bar">
        <div class="skill-bar-progress" style={{width: bar_width}}></div>
      </div>
    </div>
  </>
  )
}

export {SkillBar, SkillBarList}