export const SkillsME = [
  {
    name: "Research & Development",
    level: 100
  },
  {
    name: "Product Development",
    level: 90
  },
  {
    name: "Design for Manufacturing (DFM)",
    level: 80
  },
  {
    name: "Design for Assembly (DFA)",
    level: 90
  },
  {
    name: "Design Failure Mode and Effect Analysis (DFMEA)",
    level: 80
  },
  {
    name: "CAD Tools",
    level: 90
  },
  {
    name: "Simulating and Modelling",
    level: 70
  },
  {
    name: "Project Management",
    level: 65
  },
  {
    name: "Microsoft Excel",
    level: 80
  },
  {
    name: "Microsoft Power-Point",
    level: 80
  }
]