import { useEffect } from 'react';
import '../styles/Project.css'
import ProjectItem from '../components/ProjectItem'
import {ProjectList} from '../helpers/ProjectList'


function Projects() {
  useEffect(() => { window.scrollTo(0, 0) }, []) // To move scroll ot the top after every render

  return (
    <>
      <section>
        <h2>My Professional Projects</h2>
        <div className="projectList">
          {ProjectList.professional.map((item, idx) => {
            return <ProjectItem index={idx} type={"pro"} name={item.name} image={item.image}/>
          })}
        </div>
      </section>
      <hr/>
      <section>
        <h2>My Personal Projects</h2>
        <div className="projectList">
          {ProjectList.personal.map((item, idx) => {
            return <ProjectItem index={idx} type={"own"} name={item.name} image={item.image}/>
          })}
        </div>
      </section>
    </>
  )
}

export default Projects