import React from 'react'
import '../styles/Footer.css'
import SocialIcons from '../components/SocialIcons'

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer>
      <SocialIcons/>
      <p>cferrisroig.com &copy; {year} </p>
    </footer>
  )
}

export default Footer