import { useEffect } from 'react'
import {useParams} from 'react-router-dom'
import {ProjectList} from '../helpers/ProjectList'
import '../styles/ProjectDisplay.css'
import LinkIcon from '@mui/icons-material/Link';

function ProjectDisplay() {
  useEffect(() => { window.scrollTo(0, 0) }, []) // To move scroll ot the top after every render
  const {type, id} = useParams(); 
  const project = type === "pro" ? ProjectList.professional[id] 
                : type === "own" ? ProjectList.personal[id] 
                : null;
  const styles = {
    link: {
      display: "inline-block",
      verticalAlign: "bottom",
      color: `var(--cont-col)`,
      marginLeft: "1rem"
    }
  }

  return (
    <>
      <h1 style={{display: "inline-block"}}>{project.name}</h1>
      <a href={project.link} target="_blank" rel="noreferrer" style={styles.link}> <LinkIcon sx={{ fontSize: `var(--icon-size)` }}/></a>
      
      <div className="project">
        <img src={project.image} alt={project.name}/>
        <div className="skills">
          <p><b>Skills:</b> {project.skills}</p>
        </div>
        <div className="desc">
          <p><b>Description:</b> {project.description} </p>
        </div>
        <div className="achiev">
          <AchievementsList alist={project.achievements}/>
        </div>
      </div>
    </>
  )
}

function AchievementsList({alist}) {
  if (alist == null || alist.length === 0) {
    return (<></>)
  }

  return (
    <p><b>Achievements:</b> 
      <ul>
        {alist.map((a) => { return <li>{a}</li> })}
      </ul>
    </p>
  )
}

export default ProjectDisplay