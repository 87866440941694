import React from 'react';
import { useEffect } from 'react';
import '../styles/Home.css'
import SocialIcons from '../components/SocialIcons'
import {SkillBarList} from '../components/SkillBar'
import {SkillsCoding} from '../helpers/SkillsCoding'
import {SkillsME} from '../helpers/SkillsME'
import { SkillsLanguages } from '../helpers/SkillsLanguages';

function Home() {
  useEffect(() => { window.scrollTo(0, 0) }, []) // To move scroll ot the top after every render
  
  return (
    <> 
      <div className="about">
        <h1>Hi, my name is Cristian</h1>
        <h4>I am a <span style={{color: "var(--cont-col)"}}>Mechanical Engineer</span> passionate for learning and creating new things.</h4> 
        <SocialIcons/>
      </div>
      <section className="skills">
        <h2>Skills</h2>
        <ul className="list">
          <li className="item">
            <h3>Mechanical Engineering</h3>
            <SkillBarList skills={SkillsME}/>
          </li>
          <li className="item">
            <h3>Coding and Web-development</h3>
            <SkillBarList skills={SkillsCoding}/>
          </li>
          <li className="item">
            <h3>Languages</h3>
            <SkillBarList skills={SkillsLanguages}/>
          </li>
        </ul>
        
      </section>
    </>
  )
}

export default Home