import './styles/App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages/Home'
import Projects from './pages/Projects'
import ProjectDisplay from './pages/ProjectDisplay'
import Experience from './pages/Experience'
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
        <article>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/projects" element={<Projects/>} />
            <Route path="/experience" element={<Experience/>} />
            <Route path="/project/:type/:id" element={<ProjectDisplay/>} />
          </Routes>
        </article>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
