import React from 'react'
import {useNavigate} from 'react-router-dom'

function ProjectItem({image, name, index, type}) {
  const navigate = useNavigate();
  return (
    <div className="projectItem" onClick={() => {navigate("/project/" + type + "/" + index)}}>
      <div style={{ backgroundImage: `url(${image})` }} className="bgImage"/>
      <h3> {name} </h3>
    </div>
  )
}

export default ProjectItem